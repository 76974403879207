$konfoo-red: #d91920;

// Dark theme (default)
$color-fg: #aaaaaa;
$color-bg: #2d2d2d;

$color-border: #cccccc;

$color-select-border: #8a8a8a;
$color-select-bg: #3d3d3d;
$color-select-fg: #e9e9e9;

$color-btn-fg: #3b3b3b;
$color-btn-primary: #00d25a;
$color-btn-primary-disabled: desaturate($color-btn-primary, 90%);
$color-btn-secondary: #00a0d2;

$color-table-odd: rgba(255, 255, 255, 0.12);
$color-table-even: rgba(255, 255, 255, 0.05);


// Light theme (uncomment to enable)
$color-primary: #6aa60a;
$color-secondary: #a6940a;
$color-complementary: #460aa6;
$color-triadic-1: #0aa646;
$color-triadic-2: #159adb;

$color-fg: #3d3d3d;
$color-bg: #f6f6f6;

$color-border: #747474;

$color-select-border: #3d3d3d;
$color-select-bg: #f6f6f6;
$color-select-fg: #3d3d3d;

$color-btn-fg: #ffffff;
$color-btn-primary: #000000;
$color-btn-primary-disabled: lighten(desaturate($color-btn-primary, 90%), 20%);
$color-btn-secondary: #a6940a;
// $color-btn-secondary: #00a0d2;

$color-table-odd: rgba(0, 0, 0, 0.12);
$color-table-even: rgba(0, 0, 0, 0.05);
