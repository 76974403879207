// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@font-face {
	font-family: open-sans;
	src: url('./assets/OpenSans-Regular.ttf') format('truetype');
}

// Konfoo red - $konfoo-red
@import 'variables';

// Light Theme Text
$dark-text: #3d3d3d;
$dark-darker-text: rgba($dark-text, 0.87);
//$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-darker-text, 0.54);
$dark-disabled-text: rgba($dark-darker-text, 0.38);
$dark-dividers: rgba($dark-darker-text, 0.12);
$dark-focused: rgba($dark-darker-text, 0.12);

$dark-primary-text: #ffffff;
//$dark-accent-text: #ff0000;
//$dark-disabled-text: #ff0000;
//$dark-dividers: #ff0000;
//$dark-focused: #ff0000;

$bw-palette: (
	50: #f5f5f5,
	100: #e9e9e9,
	200: #d9d9d9,
	300: #c4c4c4,
	400: #9d9d9d,
	500: #7b7b7b,
	600: #555555,
	700: #434343,
	800: #262626,
	900: #000000,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
	)
);

$konfoo-palette: (
	50: #ffeaed,
	100: #ffcbcf,
	200: #f49694,
	300: #eb6c6a,
	400: #f54843,
	500: #f93424,
	600: #eb2725,
	700: #d9191f,
	800: #cc0c18,
	900: #be0007,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
	)
);

@include mat.core();

$knf-primary: mat.define-palette($bw-palette, 500);
$knf-accent: mat.define-palette($konfoo-palette, 200, 100, 700);

$knf-theme: mat.define-light-theme((
	color: (
		primary: $knf-primary,
		accent: $knf-accent,
		//warn: $knf-warn,
	),
	typography: mat.define-typography-config(),
	density: 0,
));

@include mat.core-theme($knf-theme);
@include mat.all-component-themes($knf-theme);

//@import "~@angular/material/prebuilt-themes/indigo-pink.css";

//$mat-light-theme-foreground: (
//  base:              black,
//  divider:           $dark-dividers,
//  dividers:          $dark-dividers,
//  disabled:          $dark-disabled-text,
//  disabled-button:   rgba($dark-text, 0.26),
//  disabled-text:     $dark-disabled-text,
//  elevation:         black,
//  secondary-text:    $dark-accent-text,
//  hint-text:         $dark-disabled-text,
//  accent-text:       $dark-accent-text,
//  icon:              $dark-accent-text,
//  icons:             $dark-accent-text,
//  text:              $dark-primary-text,
//  slider-min:        $dark-primary-text,
//  slider-off:        rgba($dark-text, 0.26),
//  slider-off-active: $dark-disabled-text,
//);
//
//// Dark Theme text
//$light-text: #aaaaaa;
//$light-primary-text: $light-text;
//$light-accent-text: rgba($light-primary-text, 0.7);
//$light-disabled-text: rgba($light-primary-text, 0.5);
//$light-dividers: rgba($light-primary-text, 0.12);
//$light-focused: rgba($light-primary-text, 0.12);
//
//$mat-dark-theme-foreground: (
//  base:              $light-text,
//  divider:           $light-dividers,
//  dividers:          $light-dividers,
//  disabled:          $light-disabled-text,
//  disabled-button:   rgba($light-text, 0.3),
//  disabled-text:     $light-disabled-text,
//  elevation:         black,
//  hint-text:         $light-disabled-text,
//  secondary-text:    $light-accent-text,
//  accent-text:       $light-accent-text,
//  icon:              $light-text,
//  icons:             $light-text,
//  text:              $light-text,
//  slider-min:        $light-text,
//  slider-off:        rgba($light-text, 0.3),
//  slider-off-active: rgba($light-text, 0.3),
//);
//
//// Background config
//// Light bg
//$light-background:    #ffffff;
//$light-bg-darker-5:   darken($light-background, 5%);
//$light-bg-darker-10:  darken($light-background, 10%);
//$light-bg-darker-20:  darken($light-background, 20%);
//$light-bg-darker-30:  darken($light-background, 30%);
//$light-bg-lighter-5:  lighten($light-background, 5%);
//$dark-bg-tooltip:     lighten(#2d2d2d, 20%);
//$dark-bg-alpha-4:     rgba(#2d2d2d, 0.04);
//$dark-bg-alpha-12:    rgba(#2d2d2d, 0.12);
//
//$mat-light-theme-background: (
//  background:               $light-background,
//  status-bar:               $light-bg-darker-20,
//  app-bar:                  $light-bg-darker-5,
//  hover:                    $dark-bg-alpha-4,
//  card:                     $light-bg-lighter-5,
//  dialog:                   $light-bg-lighter-5,
//  tooltip:                  $dark-bg-tooltip,
//  disabled-button:          $dark-bg-alpha-12,
//  raised-button:            $light-bg-lighter-5,
//  focused-button:           $dark-focused,
//  selected-button:          $light-bg-darker-20,
//  selected-disabled-button: $light-bg-darker-30,
//  disabled-button-toggle:   $light-bg-darker-10,
//  unselected-chip:          $light-bg-darker-10,
//  disabled-list-option:     $light-bg-darker-10,
//);
//
//// Dark bg
//$dark-background:     #2d2d2d;
//$dark-bg-lighter-5:   lighten($dark-background, 5%);
//$dark-bg-lighter-10:  lighten($dark-background, 10%);
//$dark-bg-lighter-20:  lighten($dark-background, 20%);
//$dark-bg-lighter-30:  lighten($dark-background, 30%);
//$light-bg-alpha-4:    rgba(#ffffff, 0.04);
//$light-bg-alpha-12:   rgba(#ffffff, 0.12);
//
//// Background palette for dark themes.
//$mat-dark-theme-background: (
//  background:               $dark-background,
//  status-bar:               $dark-bg-lighter-20,
//  app-bar:                  $dark-bg-lighter-5,
//  hover:                    $light-bg-alpha-4,
//  card:                     $dark-bg-lighter-5,
//  dialog:                   $dark-bg-lighter-5,
//  tooltip:                  $dark-bg-lighter-20,
//  disabled-button:          $light-bg-alpha-12,
//  raised-button:            $dark-bg-lighter-5,
//  focused-button:           $light-focused,
//  selected-button:          $dark-bg-lighter-20,
//  selected-disabled-button: $dark-bg-lighter-30,
//  disabled-button-toggle:   $dark-bg-lighter-10,
//  unselected-chip:          $dark-bg-lighter-20,
//  disabled-list-option:     $dark-bg-lighter-10,
//);
//
//body {
//  --primary-color: #000000;
//  --primary-lighter-color: #d2e4b6;
//  --primary-darker-color: #000000;
//  --text-primary-color: #{$dark-primary-text};
//  --text-primary-lighter-color: #{$dark-primary-text};
//  --text-primary-darker-color: #{$light-primary-text};
//}
//$mat-primary: (
//  main: #000000,
//  lighter: #d2e4b6,
//  darker: #000000,
//  200: #000000, // For slide toggle,
//  contrast : (
//    main: $dark-primary-text,
//    lighter: $dark-primary-text,
//    darker: $light-primary-text,
//  )
//);
//$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);
//
//
//body {
//  --accent-color: #ffffff;
//  --accent-lighter-color: #e4dfb6;
//  --accent-darker-color: $konfoo-red;
//  --text-accent-color: #{$dark-primary-text};
//  --text-accent-lighter-color: #{$dark-primary-text};
//  --text-accent-darker-color: #{$light-primary-text};
//}
//$mat-accent: (
//  main: #fff,
//  lighter: #e4dfb6,
//  darker: $konfoo-red,
//  200: $konfoo-red, // For slide toggle,
//  contrast : (
//    main: $dark-primary-text,
//    lighter: $dark-primary-text,
//    darker: $light-primary-text,
//  )
//);
//$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);
//
//
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
////  The following line adds:
////    1. Default typography styles for all components
////    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
////  If you specify typography styles for the components you use elsewhere, you should delete this line.
////  If you don't need the default component typographies but still want the hierarchy styles,
////  you can delete this line and instead use:
////    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
////@include mat.all-legacy-component-typographies();
////@include mat.legacy-core();
//@include mat.core();
//
//// Theme Config
//body {
//  --warn-color: $konfoo-red;
//  --warn-lighter-color: $konfoo-red;
//  --warn-darker-color: $konfoo-red;
//  --text-warn-color: #{$light-primary-text};
//  --text-warn-lighter-color: #{$dark-primary-text};
//  --text-warn-darker-color: #{$light-primary-text};
//}
//$mat-warn: (
//  main: $konfoo-red,
//  lighter: $konfoo-red,
//  darker: $konfoo-red,
//  200: $konfoo-red, // For slide toggle,
//  contrast : (
//    main: $light-primary-text,
//    lighter: $dark-primary-text,
//    darker: $light-primary-text,
//  )
//);
//$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
//
//$theme: (
//  primary: $theme-primary,
//  accent: $theme-accent,
//  warn: $theme-warn,
//  is-dark: false,
//  foreground: $mat-light-theme-foreground,
//  background: $mat-light-theme-background,
//);
//$altTheme: (
//  primary: $theme-primary,
//  accent: $theme-accent,
//  warn: $theme-warn,
//  is-dark: true,
//  foreground: $mat-dark-theme-foreground,
//  background: $mat-dark-theme-background,
//);
//
//// Theme Init
////@include mat.all-legacy-component-themes($theme);
//
////.theme-alternate {
////	@include mat.all-legacy-component-themes($altTheme);
////}
